import * as React from "react"
import { Link } from 'gatsby'

import { DefaultLayout } from "../../layouts";
import { SEO, Container } from "../../components";

import styles from './condiciones.module.scss';

// markup
const Legal = () => {
    return (
        <DefaultLayout>
            <SEO title="Calendab · Condiciones" />
            <div className={styles.container}>
                <div className={styles.core}>
                    <Container>
                        <h2 className={styles.title}>Condiciones Generales de Contratación</h2>
                        <div className={styles.body}>
                            <h3 className={styles.subtitle}>Información General</h3>
                            <p className={styles.text}>
                                Identidad: Calendab <br/>
                                Domicilio social: Carr. de Sant Llorenç 12, Castellar del Vallès<br/>
                                {/* DNI: @@@@@@@<br/>
                                Teléfono: @@@@@<br/> */}
                                Correo Electrónico: hola@calendab.com<br/>
                                Contacto: Luis Moreno<br/>
                                Nombre del dominio: www.calendab.com
                            </p>
                            <h3 className={styles.subtitle}>Ámbito de aplicación de las Condiciones Generales de Contratación</h3>
                            <p className={styles.text}>Siempre y cuando no se establezca otra cosa, estas Condiciones Contractuales de Contratación de Calendab. para clientes (“CGC”) se aplicarán con exclusividad en todas las relaciones contractuales en las que Calendab. (a partir de ahora denominada "Calendab") preste sus servicios (tal y como se definirá más adelante), incluido el soporte y el servicio de asesoramiento relacionado con este, a otras empresas, personas jurídicas de derecho público o patrimonios especiales de carácter público. La regulación se aplicará de forma correspondientemente a las relaciones precontractuales.</p>


                            <p className={styles.text}>Desarrollo y gestión de software con soporte online (SaaS). Con dichos desarrollos, Calendab busca facilitar a centros y profesionales la gestión de reservas, CRM de clientes, gestión de horarios y agenda de sus negocio.</p>

                            <h3 className={styles.subtitle}>1. PERFECCIONAMIENTO DEL CONTRATO, PARTES</h3>
                            <p className={styles.text}>1.1 Calendab. Calendab ofrece a las pequeñas y medianas empresas, a través de su página web, un software basado en la web para la reserva de citas online y para la gestión de clientes (“software”).</p>
                            <p className={styles.text}>1.2 Contrato. El "contrato" está formado por el conjunto de la orden de pedido, que el cliente ha firmado, estas CGC y las descripciones del servicio correspondientes al módulo de servicio respectivo que el cliente haya elegido y que se pueden encontrar en la página web de Calendab (también están recogidas en esta lista completa de funciones).</p>
                            <p className={styles.text}>1.3 Perfeccionamiento del contrato. El cliente celebra un contrato oneroso con Calendab.El cliente podrá escoger entre los diferentes planes de servicios. El contrato se perfecciona cuando (i) el cliente firma una orden del pedido (encargo) y Calendab confirma al cliente mediante un correo electrónico la aceptación de la oferta (confirmación del encargo), o, (ii) cuando el cliente y Calendab acuerdan celebrar el contrato a través de la emisión de la oferta y la declaración de aceptación (confirmación del encargo), por medio de correo electrónico, fax o a través de la página web de Calendab (o por una combinación de dichas vías de aceptación).</p>
                            <p className={styles.text}>1.4 No se celebran contratos con consumidores. Calendab no presta sus servicios a consumidores finales, sino exclusivamente para la finalidad empresarial o autónoma de la actividad del cliente.</p>
                            <p className={styles.text}>1.5 No existe el derecho de revocación. No existe derecho legal de revocación debido a que el cliente no es un consumidor.</p>
                            <p className={styles.text}>1.6 Expresamente no se aceptan las CGC ajenas. Respecto a los conflictos que pudieran derivarse de otras disposiciones, prevalecerá lo estipulado en estas CGC. Esto aplica especialmente a las Condiciones Generales de Contratación del cliente, incluso si Calendab aceptase una orden de pedido de cualquier cliente en la que éste refiera a sus propias Condiciones Generales de Contratación, e inclusive si se adjuntaran dichas Condiciones Generales de Contratación y Calendab no las objetara.</p>
                            <p className={styles.text}>1.7 Uso. El uso del software de Calendab requiere la creación de una cuenta de usuario (“la cuenta”). Al cliente se le enviarán los datos de acceso necesarios en el marco de un período de prueba gratuito o, a más tardar, al comienzo del contrato.</p>
                            <p className={styles.text}>1.8 Se prohíbe el uso de los servicios por parte de terceros. El derecho del cliente al uso de los servicios de Calendab está limitado al uso con fines comerciales propios por parte de la empresa indicada en la orden del pedido, incluidas todas sus sucursales y establecimientos permanentes que carezcan de independencia legal en aquellos países donde Calendab es operativo. Se prohíbe otorgar una concesión de uso del servicio a terceros así como ponerlo a su disposición o permitir que terceros lo usen, incluidas aquí las empresas afiliadas. Sin embargo, el cliente sí está autorizado a usar los servicios de Calendab, dentro del ámbito previsto contractualmente, para ofrecer o proporcionar sus servicios o productos frente a los clientes finales.</p>
                            <h3 className={styles.subtitle}>2. SERVICIOS DE CALENDAB</h3>
                            <p className={styles.text}>2.1 Sinopsis. Calendab pone a disposición de sus clientes el software con los planes de servicios seleccionados para su uso a través del Internet (“software como servicio”), y aporta otros servicios relacionados con ello, como, por ejemplo, el desarrollo de páginas web o servicios de hosting o explotación (en conjunto, los "servicios"). Los servicios de Calendab consisten en determinados planes de servicios que el cliente puede elegir, de forma unitaria o en combinación con otros módulos adicionales, en la orden de pedido, a través de la página web o de otra forma en Calendab. Los detalles referentes al alcance de los planes de servicios se desprenden de la orden del pedido y de la descripción del servicio correspondiente.</p>
                            <p className={styles.text}>2.2 Software como servicio. El cliente recibe, por el período de vigencia del contrato, el derecho no exclusivo, no sublicenciable e intransferible a acceder al plan de servicios contratado, utilizando un navegador y una conexión a Internet, y el derecho a utilizarlo de forma exclusiva para su actividad empresarial o autónoma. Esto incluye el derecho, en la medida que sea necesario, de almacenar y ejecutar códigos de programación (por ejemplo, JavaScript) temporalmente en el ordenador del usuario correspondiente (por ejemplo, en la memoria del ordenador o en la caché web).</p>
                            <p className={styles.text}>2.3 Widgets. Calendab pone a disposición de sus clientes códigos de programa para integrar funciones determinadas de un plan de servicios en determinados sitios web del cliente (por ejemplo, páginas web basadas en DUDA o páginas de Facebook) ("widgets"). Calendab otorga al cliente el derecho simple, no transferible, no sublicenciable y limitado temporalmente a la vigencia de este contrato a utilizar los widgets, para integrar los planes de servicios contratados en los sitios web del cliente (por ejemplo, página web de la empresa, la página de Facebook), y sobre todo, para instalarlos en los servidores del cliente o en alguno de los que utilice su proveedor de alojamiento web y hacerlos funcionar desde allí. Respecto al uso de widgets concretos, es posible que sean de aplicación licencias complementarias y condiciones de uso del fabricante de sistemas de gestión de contenidos u operadores de redes sociales (por ejemplo, Facebook). Además, los widgets pueden contener componentes de terceros que estén sujetos a los llamados términos de licencia de código abierto. En caso de discrepancia, estos tienen prioridad sobre el presente contrato.</p>
                            <p className={styles.text}>2.4 Otros servicios de Calendab. Otros servicios técnicos, como la importación de datos existentes del cliente en la Cloud o la integración de determinadas funciones en los sitios web del cliente, así como todos los demás servicios que no se hayan acordado expresamente y por escrito en el contrato o en acuerdos posteriores, solo serán realizados por Calendab mediante acuerdo separado.</p>
                            <p className={styles.text}>2.7 Disponibilidad. Calendab ofrece al cliente los servicios con una disponibilidad de acuerdo a lo establecido en el apartado "Service Levels" de la correspondiente descripción del servicio.</p>
                            <p className={styles.text}>(a) Lugar de entrega. Calendab entrega sus servicios en el punto de conexión del centro de datos utilizado por Calendab a Internet. El cliente es responsable de la conexión entre el centro de datos y él mismo.</p>
                            <p className={styles.text}>(b) Disponibilidad conseguida. Para el cálculo de la disponibilidad conseguida no se considerarán pérdidas ocasionadas por razones de causa mayor (por ejemplo, huelgas, disturbios, desastres naturales). Además, no se tendrán en cuenta cierres realizados por Calendab por razones de seguridad, siempre y cuando Calendab haya tomado las medidas de seguridad pertinentes (por ejemplo, denial of service attack, la vulnerabilidad grave de un software de un tercero empleado sin parche disponible) o que Calendab realice debido a que el cliente se encuentre en mora respecto de sus obligaciones contractuales (por ejemplo, el pago de la retribución acordada).</p>
                            <p className={styles.text}>2.8 Soporte. Calendab ofrece un soporte técnico gratuito para proveer al cliente asistencia técnica relativa al uso de sus servicios. Nuestro horario de soporte: Lunes a Viernes de 9:00am a 18:00pm. Aplica a todos los días con excepción de los días festivos legales. Los días 24 y 31 de diciembre, el soporte está disponible de 9:00 a 12:00 horas. El tiempo de espera para la primera respuesta a las solicitudes por correo electrónico puede variar según el volumen de trabajo. Calendab se esfuerza para que reciba una respuesta en un tiempo razonable. Las solicitudes de asistencia recibidas fuera de las horas de atención establecidas se considerarán recibidas el siguiente día hábil. El soporte no incluye: Asesoramiento general, formación sobre marketing, asesoramiento jurídico, o trabajos de instalación. El soporte técnico relacionado con los sitios web del cliente solo se ofrece en lo concerniente al widget y solo en la medida que éste se vea afectado.</p>
                            <p className={styles.text}>2.9 Ninguna entrega del código fuente, desarrollos u otro tipo de información. Todo el software, aplicaciones (webs o nativas), páginas web, widgets u otros servicios, incluido aquél software que se hubiese programado para o por encargo del cliente, se entregará (salvo que expresamente se pacte lo contrario) al cliente o a un tercero tan solo en código ejecutable y sin documentación. El cliente no tendrá derecho a la entrega del código fuente, material de desarrollo u otro tipo de información.</p>
                            <p className={styles.text}>2.10 Servicios proporcionados por terceros. Calendab podrá emplear a terceros para proporcionar el servicio en cualquier momento y bajo su propio criterio, por ejemplo, respecto al alojamiento de la página web y aplicaciones, envío de SMS o correos electrónicos, servicios en línea para la creación o puesta a disposición de aplicaciones.</p>
                            <h3 className={styles.subtitle}>3. MODIFICACIÓN DE LOS SERVICIOS</h3>
                            <p className={styles.text}>3.1 El cliente es consciente de que los planes de servicios de Calendab consisten en un software estándar, que, o bien, se prestan a título de “software como servicio” y con ello una variedad de clientes puede acceder a un sistema centralizado, o bien funciona en gran parte de forma automática. Las economías de escala que genera este modelo informático de tenencia múltiple o automatizada sólo pueden ser aprovechadas en tanto que sea un software único, que se puede desarrollar continuamente. Por lo tanto, las partes acuerdan:</p>
                            <p className={styles.text}>(a) Motivo justificado. Calendab puede modificar sus servicios, los planes de servicios, las páginas del portal y los widgets (incluyendo los requisitos del sistema) por un motivo justificado. Una de estas razones en concreto sería si el cambio se debe a (i) la necesidad de adaptarse a una nueva legislación o jurisprudencia, (ii) la protección de la seguridad del sistema o (iii) para evitar un mal uso.</p>
                            <p className={styles.text}>(b) Desarrollo continuo. Además, Calendab puede modificar sus servicios, los planes de servicios, las páginas del portal y los widgets dentro del marco del desarrollo continuo (por ejemplo, al desconectar funciones antiguas que sean reemplazadas por nuevas), especialmente para tener en cuenta los nuevos adelantos técnicos.</p>
                            <p className={styles.text}>3.2 Calendab avisará al cliente de los principales cambios, que pudieran tener un efecto negativo, por lo general, cuatro semanas antes de la entrada en vigor de los mismos. En el caso de que debido a una modificación, los intereses de un cliente pudieran verse afectados negativamente de forma que el mantenimiento del contrato ya no pudiera ser razonable para él, éste podrá rescindir el plan de servicios afectado en el plazo de una semana antes de la entrada en vigor de la modificación anunciada. Siempre y cuando el cliente no realice la rescisión, la modificación entrará en vigor en la fecha indicada. Calendab advertirá de ello en la información.</p>
                            <h3 className={styles.subtitle}>4. RETRIBUCIÓN Y MOROSIDAD</h3>
                            <p className={styles.text}>4.1 Retribución. El cliente está obligado al pago de la retribución acordada en la orden del pedido o en las modificaciones o adiciones posteriores del contrato por la puesta a disposición de los servicios durante el período de duración del contrato. La retribución consiste en una cuota mensual (por lo general recurrente) dependiente del plan de servicios elegido y, posiblemente, una cuota basada en el uso (por ejemplo, para los SMS enviados) y, posiblemente, una cuota de instalación (que se paga una sola vez).</p>
                            <p className={styles.text}>4.3 Vencimiento. Salvo pacto en contrario, la cuota base deberá pagarse por anticipado en cada caso y la cuota por uso al finalizar el período de liquidación correspondiente. La cuota de instalación se pagará en el momento de la celebración del contrato.</p>
                            <p className={styles.text}>4.4 Facturación. Calendab emitirá una factura cuando el contrato cobre vigencia y, en adelante, ese mismo día para los meses venideros (por ejemplo, si el contrato se realizó el 12 de febrero, la primera factura se emitirá ese día y, así, sucesivamente el 12 de marzo, el 12 de abril, etc.). Si se ha acordado un pago anual, la cuota básica se facturará al inicio del contrato y, después, por anticipado, al inicio de cada año contractual venidero. Las facturas se emiten mediante el envío de una factura en formato PDF a la dirección de correo electrónico especificada en la orden del pedido.</p>
                            <p className={styles.text}>4.5 Pago. El pago de la factura se realizará, salvo acuerdo en contrario, a través de adeudo directo SEPA. El cliente se compromete a proporcionar a Calendab el mandato de adeudo directo SEPA correspondiente. El cobro se realizará después de la facturación; se realizará al comienzo del ciclo de facturación escogido.</p>
                            <p className={styles.text}>4.6 Precio neto. Los precios indicados no incluyen el impuesto al valor añadido (IVA).</p>
                            <p className={styles.text}>4.7 Mora en el pago, bloqueo de los datos de acceso, rescisión. A partir del vencimiento del plazo de pago, Calendab podrá exigir el pago de intereses de demora que se calcularán en base a los intereses de demora legales vigentes en dicho momento. En el caso de que el cliente se encontrara en mora respecto de los pagos y, una vez se hubiera requerido el mismo y transcurrido el plazo sin haberlo realizado, Calendab podrá bloquear temporalmente el acceso a los servicios que se hayan puesto a disposición del cliente de forma total o parcial hasta que el pago se hubiese completado. Durante el tiempo en que el acceso se hubiese bloqueado, el cliente no podrá acceder a los datos almacenados por Calendab. Si el cliente se retrasase durante dos meses naturales en el pago de la remuneración —o de una parte considerable de esta— o si, durante más de dos meses, se retrasase en el pago de la remuneración en una cantidad mayor que el doble de la cuota mensual, Calendab estará facultada a rescindir el contrato de forma extraordinaria.</p>
                            <p className={styles.text}>4.8 Calendab podrá exigir el pago inmediato de todas las deudas contraídas durante la entera relación comercial cuando un cliente que se haya retrasado más de un mes en el pago de dos cuotas mensuales no abone el importe correspondiente en el plazo de un mes desde la recepción de un requerimiento de pago por escrito y Calendab le haya advertido de las consecuencias en el escrito del requerimiento o cuando Calendab, el cliente u otro acreedor hayan solicitado el concurso de acreedores o hayan incoado cualquier otro procedimiento legal equivalente y se haya abierto tal procedimiento o la solicitud de apertura haya sido rechazada por falta de activos. Este derecho a una exigencia de pago global inmediata no podrá ejercerse cuando la cuantía de las deudas sea de carácter menor o insignificante..</p>
                            <p className={styles.text}>4.9. En los casos en que Calendab lleve a cabo una rescisión extraordinaria por motivo justificado, en particular de conformidad con la cláusula 4.7, se aplicará mutatis mutandis la cláusula 4.8</p>
                            <h3 className={styles.subtitle}>5. DEBERES Y OBLIGACIONES DEL CLIENTE</h3>
                            <p className={styles.text}>5.1 Los siguientes deberes de colaboración son obligaciones principales del cliente y no pueden clasificarse solamente como obligaciones auxiliares o meras obligaciones.</p>
                            <p className={styles.text}>5.2 El cliente está obligado a tener disponible, junto con el representante, una persona de contacto cualificada que tenga la potestad de tomar las decisiones necesarias o poder alcanzar sin demora las que sean necesarias para el cumplimiento del servicio acordado contractualmente. El cliente queda obligado a comunicar de inmediato cualquier cambio en referencia a la persona de contacto (junto con el representante).</p>
                            <p className={styles.text}>5.3 Instalación. El cliente realizará el mismo la configuración del plan de servicios correspondiente (ajustes individuales, introducción/importación de datos, implementación de plug-ins) y será responsable de ello. Esto último se aplicará también incluso cuando Calendab ayude al cliente en la instalación.</p>
                            <p className={styles.text}>5.4 No existe la obligación de realizar una modificación del plan de servicios correspondiente o de los widgets según los deseos del cliente.</p>
                            <p className={styles.text}>5.5 Uso adecuado. El cliente utilizará los servicios proporcionados por Calendab solo bajo las disposiciones contractuales establecidas, sin perjudicar el derecho de terceros (por ejemplo, derechos de autor, marcas registradas) y observando todas las leyes y reglamentos aplicables. En su uso del software cumplirá, en particular, las normas sobre protección de datos, derecho de la competencia, derechos de autor y cualquier obligación de confidencialidad aplicable. El cliente no procesará datos de forma maliciosa o ilegal, ni abusará de los servicios de Calendab de ningún otro modo.</p>
                            <p className={styles.text}>5.6 Cooperación del cliente, plazo de cooperación. En muchos de los planes de servicios contratables (por ejemplo, la creación de la página web), es necesaria la cooperación del cliente dentro del marco de la prestación del servicio. El cliente está obligado a cooperar, en la extensión que sea necesaria y sin percibir ninguna remuneración por ello, en la prestación del servicio por parte de Calendab; sobre todo deberá poner a disposición todos los datos que sean precisos para la configuración del módulo de servicio correspondiente, (por ejemplo, los datos de acceso a la página web o los datos de contacto del webmaster), así como posibilitar el acceso a los servicios a través de instalaciones de infraestructura y de telecomunicaciones. El cliente se compromete a realizar las posibles acciones necesarias para la cooperación en un tiempo razonable y a cumplir con los plazos acordados o con los plazos, razonables, indicados por Calendab para la cooperación. Salvo que se acuerde lo contrario contractualmente, se entiende que un plazo o tiempo razonable para la cooperación del cliente es un período de cinco días. Calendab no asume ninguna responsabilidad respecto de los atrasos imputables a la esfera del cliente.</p>
                            <p className={styles.text}>5.7 El cliente está obligado a asegurar por sus propios medios que existan los requisitos técnicos mínimos. Para conseguir un uso óptimo de la oferta y de las funciones de Calendab, el cliente deberá emplear la versión más actualizada de los buscadores Google Chrome o Mozilla Firefox. Además, en los ajustes del buscador empleado deberá aceptarse el uso de cookies. Si el cliente no cumpliera con esos requisitos técnicos mínimos podrían producirse, bajo determinadas circunstancias, limitaciones respecto del uso de los servicios.</p>
                            <p className={styles.text}>5.8 Presunción de aceptación. El cliente estará obligado a aceptar el diseño final del trabajo ofrecido por Calendab (por ejemplo, la aplicación o la página web) en el plazo de cinco días laborales siempre y cuando no existan defectos esenciales. Si el cliente no realiza la aceptación y tampoco reclama ningún defecto, se entenderá que se ha aceptado el trabajo, una vez haya transcurrido dicho plazo.</p>
                            <p className={styles.text}>5.9 Seguridad y responsabilidad del cliente. El cliente mantendrá estándares de seguridad adecuados para el uso de los servicios por los usuarios autorizados correspondientes. El cliente es el único responsable de valorar la idoneidad de los servicios para sus procesos empresariales y de cumplir todas las disposiciones legales aplicables en relación con sus datos y el uso de los servicios.</p>
                            <p className={styles.text}>5.10 Deseos y especificaciones del cliente. Siempre que se prevea, en la descripción del módulo de servicio que corresponda, la realización de determinados servicios, tras acuerdo con el cliente o según los deseos o indicaciones del mismo, Calendab se esforzará en aplicar dichos acuerdos, deseos o especificaciones, siempre y cuando sean aplicables bajo un coste razonable para Calendab (en relación con la contraprestación del cliente). Calendab no puede garantizar la implementación técnica exacta de los deseos del cliente y tampoco está obligado a ello. Con frecuencia no es posible para Calendab conseguir una reproducción exacta, sobre todo cuando es necesario usar elementos de páginas web existentes del cliente.</p>
                            <p className={styles.text}>5.11 Contenidos del cliente. En algunos de los planes de servicios contratados por el cliente (por ejemplo, la creación de una aplicación móvil o página web) es necesario que el cliente suministre contenido (por ejemplo, imágenes, logotipos, texto, vídeos, deseos respecto al diseño, aviso legal, etc.) que Calendab procesará y/o publicará dentro del marco de la prestación del servicio. El cliente se compromete a poner a disposición de Calendab los contenidos que se necesiten cada vez en un tiempo razonable y en el formato indicado por Calendab, que será habitual en el mercado. Los contenidos que el cliente haya puesto a disposición no serán controlados por Calendab, tampoco se probará su adecuación a la legalidad, ni serán revisados para su corrección. Calendab no redacta, prepara o edita textos, imágenes, vídeos, gráficos o logotipos, ni tampoco los pone a la disposición del cliente (siempre y cuando no sea necesario por razones puramente técnicas).</p>
                            <p className={styles.text}>5.12 Derecho de uso de los contenidos del cliente. Los derechos de uso de los contenidos puestos a disposición por parte del cliente le pertenecen a este. Calendab está autorizado a emplear el contenido del cliente, especialmente a reproducir, procesar, divulgar, hacer accesible al público o publicarlo de cualquier otra manera, siempre que esto sea necesario dentro del marco de la prestación del servicio. Con ese fin, Calendab se reserva el derecho no exclusivo, transmisible, sublicenciable y espacialmente ilimitado del uso de los contenidos del cliente durante el período de duración del contrato.</p>
                            <p className={styles.text}>5.13 Responsabilidad por el contenido del cliente. El cliente es el responsable único y exclusivo respecto de los contenidos que ponga a disposición de Calendab o que Calendab emplee por indicación del cliente en el marco de la prestación del servicio. El cliente deberá asegurarse de que dispone de todos los derechos relativos a la protección comercial, intelectual o de autor respecto de los contenidos puestos a disposición (por ejemplo, sobre derechos de autor, de marcas, denominación o de diseño) que sean necesarios para la prestación del servicio por parte de Calendab. Además, el cliente se compromete a no facilitar ningún contenido que contravenga la legislación vigente y las buenas costumbres (sobre todo contenidos pornográficos, racistas, xenófobos, de extrema derecha u otros contenidos reprensibles) o que lesione derechos de terceros (en particular, los derechos de la personalidad). Calendab declara expresamente que no asumirá ningún tipo de responsabilidad frente a la lesión de derechos relativos a la protección comercial, intelectual o derechos de autor de terceros. Calendab tampoco responderá en caso de incumplimientos de prohibiciones legales, de las buenas costumbres o los derechos de un tercero mediante los contenidos puestos a disposición por parte del cliente.</p>
                            <p className={styles.text}>5.14 Envío de correos electrónicos, SMS y mensajes de texto. El cliente enviará a través del servicio boletines de noticias, SMS, mensajes de texto, correos electrónicos y otro tipo de comunicaciones tan sólo a los destinatarios que hayan dado su consentimiento legal, o bien a aquellos destinatarios en relación a cuyo envío – si procede – se cumplan los requisitos de la ley contra la competencia desleal aplicable. En caso de duda, es obligación del cliente informarse sobre la permisibilidad legal del envío de comunicaciones electrónicas.</p>
                            <p className={styles.text}>5.15 Datos obligatorios. El cliente es el responsable legal de las páginas web donde se integran los widgets de Calendab. Calendab presta sus servicios en este sentido exclusivamente como un proveedor técnico. El cliente se asegurará de incluir los campos legalmente obligatorios, por ejemplo el aviso legal y las políticas de privacidad y protección de datos. El cliente también se encargará de cumplir con los requisitos legales obligatorios, incluyendo en cada caso la información obligatoria para el envío de correos electrónicos y otras comunicaciones.</p>
                            <p className={styles.text}>5.16 Copias de seguridad. Es responsabilidad del cliente conservar copias de seguridad de los datos que almacena en el sistema, así como de hacer él mismo copias de seguridad de los datos recogidos a través del servicio de Calendab. En el caso de que el cliente incumpla esta obligación, la responsabilidad de Calendab por cualquier pérdida de datos que se produzca, estará limitada a aquellos daños que se hubieran producido aún si el cliente hubiese cumplido sus obligaciones de hacer y conservar copias de seguridad de forma constante y correcta.</p>
                            <p className={styles.text}>5.17 Requisitos del sistema. A menos que Calendab especifique lo contrario, el cliente deberá emplear una versión actual del navegador Internet Explorer, Apple Safari, Google Chrome o Firefox. La documentación de usuario podrá contener otros requisitos del sistema.</p>
                            <p className={styles.text}>5.18 Datos con relevancia fiscal. El cliente será el responsable de almacenar los datos de conformidad con los requisitos legales (en particular, los requisitos de la legislación mercantil y fiscal).</p>
                            <p className={styles.text}>5.19 Servicios de pago. Los servicios de pago se realizarán exclusivamente a través de un proveedor de pagos externo y serán de aplicación sus Condiciones Generales de Contratación. Con el objeto de que Calendab pueda emplear dichos servicios de pago, el cliente se manifiesta de acuerdo en poner a disposición de Calendab información completa sobre si mismo y su empresa. Al mismo tiempo, autoriza a Calendab a transmitir al proveedor de servicios de pago dicha información y los datos de transacciones que se encuentren relacionados con su uso de los servicios de pago.</p>
                            <p className={styles.text}>5.20 Relación con el cliente final. El cliente es el responsable de la relación jurídica establecida entre éste y el cliente final y de la formación de un correspondiente contrato en relación con el cumplimiento del servicio.</p>
                            <h3 className={styles.subtitle}>6. DATOS DE CLIENTES Y PROTECCIÓN DE DATOS</h3>
                            <p className={styles.text}>6.1 Datos de clientes. Los datos (i) transmitidos a Calendab por el cliente sobre sus clientes finales (por ejemplo, correo electrónico, nombre, dirección, etc.), (ii) introducidos por Calendab en el momento de importar datos de clientes que suministró el cliente (como la importación de datos desde otros sistemas), y (iii) generados por los servicios de Calendab gracias a las operaciones que realizan los clientes finales (por ejemplo, solicitudes de reserva, citas, contenidos chat) (en conjunto denominados “datos del cliente”) corresponden al cliente de Calendab. Calendab actúa exclusivamente como un proveedor técnico y procesa los datos de clientes de forma confidencial. Sin embargo, Calendab tiene derecho a utilizar los datos de los clientes – incluso después de la finalización del contrato – de manera agregada o estadística para fines de análisis de fallos o de desarrollo continuo de las funciones del software y de benchmarking.</p>
                            <p className={styles.text}>6.2 Procesamiento de los datos por encargo. Sobre los datos personales de los clientes, se aplicará lo siguiente: Calendab procesará los datos de clientes finales como procesador de datos, supeditado a lo indicado en la cláusula 6.1, frase 3ra., con el único fin de prestar al cliente los servicios contractuales de Calendab y siguiendo sus instrucciones. Calendab toma las medidas técnicas y organizativas adecuadas para proteger los datos de los clientes. El cliente es el único responsable – particularmente, en cuanto a su relación con Calendab – de cumplir con derechos a la protección de datos y de velar por la legalidad en la recolección, procesamiento y uso de estos datos de los clientes, de conformidad con todos los requisitos legales, en particular, de conformidad con la Ley Española de Protección de Datos. Cuando sea necesario, el cliente obtendrá los consentimientos requeridos y hará referencia a políticas de privacidad.</p>
                            <p className={styles.text}>6.3 Datos de transacciones de pagos. En aquellos casos en los que los servicios de Calendab posibiliten servicios de pago a través de un proveedor de servicios de pago externo, los datos correspondientes de las transacciones de pagos serán almacenadas por dicho proveedor de servicios de pagos y no por Calendab.</p>
                            <h3 className={styles.subtitle}>7. RESPONSABILIDAD POR DEFECTOS</h3>
                            <p className={styles.text}>7.1 Ausencia de defectos y calidad. Calendab se compromete a proporcionar los servicios debidos según este contrato sin ningún defecto material o legal, siempre y cuando no se trate solo de componentes englobados bajo un contrato de arrendamiento de servicios. Se considera que los servicios no tienen defectos cuando dispongan de la naturaleza acordada y se adecuen al uso previsto contractualmente. El software elaborado según este contrato satisfará el criterio de su adecuación práctica y dispondrá de la calidad habitual para software de ese tipo. No se tendrán en cuenta disminuciones insignificantes de la calidad. No existirá un derecho a reclamar debido a posibles mermas de la función o averías del servicio siempre y cuando (i) se hayan provocado por el uso erróneo o indebido del servicio (por ejemplo mal uso debido a la no atención a las indicaciones de la documentación del usuario) o, (ii) se deban a un uso del servicio en un entorno de sistema y/o en combinación con hardware, software o una infraestructura técnica deficientes o que no cumplan con los requisitos que Calendab haya comunicado al cliente. La obligación de eliminación de posibles defectos del software elaborado según este contrato no obliga a adaptarlo cuando se produzcan cambios en las condiciones operativas ni ante desarrollos técnicos u operativos, tales como un cambio en el entorno informático, en concreto un cambio de hardware o de sistemas operativos (por ejemplo, nuevos dispositivos móviles o sistemas operativos).</p>
                            <p className={styles.text}>7.2 Requisitos del cliente. El cliente está obligado a comprobar antes de la celebración del contrato que la especificación del software se corresponde con sus deseos y necesidades. Tiene conocimiento de las características y condiciones funcionales principales del software.</p>
                            <p className={styles.text}>7.3 Supresión de defectos. Si existieran deficiencias en los servicios de Calendab, el cliente informará a Calendab inmediatamente y explicará las circunstancias del suceso. Calendab solucionará el defecto en un plazo de tiempo razonable. Calendab tiene derecho, en el caso de defectos del software, a mostrar al cliente las distintas opciones provisionales para arreglar el servicio, y a posponer la mejora definitiva para un momento posterior mediante una adaptación eficaz del software, siempre y cuando sea razonable para el cliente.</p>
                            <p className={styles.text}>7.4 Establecimiento de un plazo. Los establecimientos de plazo por parte del cliente precisarán de la forma escrita para que tengan eficacia. Una prórroga debe ser razonable.</p>
                            <p className={styles.text}>7.5 Defectos iniciales. La responsabilidad estricta (sin falta) por defectos iniciales queda excluida. La responsabilidad originada por la falta de Calendab se mantiene intacta.</p>
                            <p className={styles.text}>7.7 Prescripción. El derecho a reclamación por defectos prescribe a los 12 meses. Esto no se aplica en el caso de reclamaciones de garantía por daños y perjuicios en la medida en que Calendab sea legalmente responsable.</p>
                            <p className={styles.text}>7.8 Regulación legal. Para el resto de casos, se aplicarán las normas legales vigentes sobre responsabilidad por defectos.</p>
                            <p className={styles.text}>Queda excluida cualquier otra responsabilidad de Calendab, independientemente del fundamento alegado, exceptuando la responsabilidad por daños y perjuicios con base legal, especialmente por daños vitales, corporales y de salud, por la asunción de una garantía, por la ocultación fraudulenta de defectos o bajo la ley de responsabilidad por productos aplicable.</p>
                            <h3 className={styles.subtitle}>8. OBLIGACIÓN DE EXENCIÓN</h3>
                            <p className={styles.text}>En el caso de que un tercero (incluidas las administraciones públicas) realizara una reclamación a Calendab por contenidos ilegales, por la lesión de derechos de protección intelectual o comercial o derechos de autor, o debido a cualquier otra razón propia de la esfera del cliente (como perturbador tercero o coperturbador), (por ejemplo, por omisión, rescisión, rectificación, indemnización de daños, etc.) o dichos terceros hicieran valer contra Calendab derechos o le reclamaran por infracciones de derecho con base en la suposición de que el cliente hubiese lesionado sus deberes legales o contractuales (por ejemplo, envío de correos electrónicos en incumplimiento del derecho de protección de datos o de la competencia, o con datos obligatorios no cumplimentados), entonces, el cliente estará obligado a indemnizar a Calendab por los daños, perjuicios y gastos provocados por ello (incluido los gastos de defensa jurídica). El cliente tendrá también la obligación de apoyar a Calendab de todas las maneras posibles para evitar el ejercicio de tales acciones legales.</p>
                            <h3 className={styles.subtitle}>9. LIMITACIÓN DE RESPONSABILIDAD</h3>
                            <p className={styles.text}>9.1 Exclusión en ciertos casos. Calendab indemnizará por daños o reemplazará aplicaciones inútiles, independientemente del motivo legal (por ejemplo por obligaciones de derecho mercantil o asimilables, vicios materiales o jurídicos, incumplimiento de obligaciones o actuaciones no autorizadas) tan solo con el siguiente alcance:</p>
                            <p className={styles.text}>(a) la responsabilidad en caso de dolo y de garantía es ilimitada,</p>
                            <p className={styles.text}>(b) en caso de negligencia grave, Calendab responderá por el importe máximo de los daños habituales y previsibles en el momento de celebrar el contrato.</p>
                            <p className={styles.text}>(c) en caso del incumplimiento por negligencia leve de deberes esenciales, cuyo cumplimiento ponga en peligro la finalidad del contrato, o del incumplimiento de obligaciones cuyo cumplimiento sea necesario para que el contrato pueda ejecutarse de forma adecuada y que el cliente puede confiar que se cumplan (por ejemplo la pérdida absoluta de datos de cliente donde ni siquiera los datos antiguos puedan recuperarse), Calendab responderá por el importe máximo de los daños habituales y previsibles en el momento de celebrar el contrato, para todos los casos con daños que provengan y que estén relacionados con el contrato en general.</p>
                            <p className={styles.text}>Calendab deja abierta la excepción de la responsabilidad concurrente. El cliente tiene, en especial, el deber de asegurar los datos y de defensa frente a software malicioso, según el estado actual de la técnica en ese momento.</p>
                            <p className={styles.text}>Queda excluida cualquier otra responsabilidad de Calendab, independientemente del fundamento alegado, exceptuando la responsabilidad por daños y perjuicios con base legal, especialmente por daños vitales, corporales y de salud, por la asunción de una garantía, por la ocultación fraudulenta de defectos o bajo la ley de responsabilidad por productos aplicable.</p>
                            <p className={styles.text}>9.2 Ninguna garantía. Las garantías por parte de Calendab solo se expiden de forma escrita, y siempre y cuando, se las denomine explícitamente como “garantías”.</p>
                            <p className={styles.text}>9.3 Limitación del importe. En el caso de la cláusula 9.1 (c), Calendab solo responderá de forma limitada respecto de los daños previsibles para un contrato del mismo tipo, en cualquier caso limitado a un importe que se corresponda con la contraprestación que Calendab hubiese recibido del cliente en los últimos doce meses.</p>
                            <p className={styles.text}>9.4 Empleados y agentes de Calendab. Las limitaciones de responsabilidad de la cláusula 13 tendrán efecto también respecto a reclamaciones contra los empleados y agentes de Calendab.</p>
                            <h3 className={styles.subtitle}>10. DURACIÓN Y RESCISIÓN DEL CONTRATO</h3>
                            <p className={styles.text}>10.1 Duración. Este acuerdo se inicia con la celebración del contrato (véase cláusula 1.3) y, salvo pacto en contrario, tiene una duración inicial de 12 meses, que se extenderá luego cada vez por 12 meses más (periodo adicional), a menos que se decida finalizar el contrato, tanto por parte de Calendab como por parte del cliente, con un plazo de preaviso de 1 mes previo a la finalización del plazo de terminación del periodo inicial o adicional.</p>
                            <p className={styles.text}>10.2 Forma. Las rescisiones de contrato se deben presentar por escrito, por fax o bien por correo electrónico. Las rescisiones del contrato por parte del cliente deberán ser enviadas por correo electrónico a la dirección service@Calendab.com.</p>
                            <p className={styles.text}>10.3 Rescisión extraordinaria. El derecho a la rescisión extraordinaria de contrato se mantiene intacto. Un motivo justificado que facultaría a Calendab a realizar una rescisión extraordinaria del contrato existiría especialmente cuando el cliente enviara correos electrónicos publicitarios ilegales a través de los servicios o hubiera permitido el uso de los mismos por parte de un tercero o los hubiese usado para este (véase cláusula 1.9).</p>
                            <p className={styles.text}>10.4 Datos a la finalización del contrato. A partir de la finalización del contrato, el cliente no podrá acceder a sus datos de clientes. Es responsabilidad del cliente, en caso de que quiera conservar estos datos, exportarlos mediante la función de exportación y almacenar éstos para su uso posterior antes de que finalice la vigencia del contrato. Calendab no está obligado a realizar una entrega adicional de datos de clientes (por ejemplo, como SQL dump o en un formato específico). A partir de la finalización del contrato, Calendab borrará los datos de los clientes, siempre y cuando Calendab no esté obligado legalmente a almacenarlos. Si la eliminación de datos implicara un esfuerzo excesivo (por ejemplo, con respecto a copias de seguridad), Calendab podrá alternativamente bloquear el acceso a los datos de clientes en cumplimiento a la normativa de protección de datos.</p>
                            <h3 className={styles.subtitle}>11. CONFIDENCIALIDAD</h3>
                            <p className={styles.text}>11.1 Calendab y el cliente se comprometen a tratar confidencialmente toda la información, datos y documentación respecto de la otra parte, obtenidas en el marco de la realización de las obligaciones contractuales, incluidas también las reproducciones y copias realizadas, que, o bien se encuentren marcadas como confidenciales o que lo parezcan en base a las circunstancias de la transmisión o de su contenido, incluido este contrato (junto la "información confidencial"), de forma ilimitada en el tiempo, así como a protegerla como si se tratara de la propia información confidencial, al menos con el cuidado esperado de un buen comerciante. No se permite la transmisión a un tercero, siempre y cuando no sea necesario para el ejercicio de derechos o para el cumplimiento de obligaciones contractuales y dicho tercero también esté sujeto a obligaciones respecto de la confidencialidad similares a las aquí reguladas.</p>
                            <p className={styles.text}>11.2 La cláusula 11.1 no se aplicará, siempre y cuando la información confidencial (i) fuera de dominio público en el momento de la publicación, sin que existiera un comportamiento lesivo o una abstención de realizar obligaciones por parte de la parte receptora; (ii) ya fuera conocida o en propiedad de la parte receptora antes de la recepción; (iii) fuera revelada de forma legítima por otra persona sin limitaciones de la parte receptora; (iv) fuera desarrollada de forma independiente por la parte receptora sin tener acceso a información confidencial de la parte reveladora; o (v) deba publicarse por razón de un mandato administrativo según disposiciones de naturaleza legal o administrativa o por razón de una resolución judicial firme.</p>
                            <p className={styles.text}>11.3 Ninguna de las partes empleará el nombre de la otra parte con fines publicitarios o similares sin obtener su consentimiento previo. Calendab está facultado, sin embargo, a emplear el nombre de sus clientes en listas de referencias o en el marco de sus actividades de marketing.</p>
                            <h3 className={styles.subtitle}>12. DISPOSICIONES FINALES</h3>
                            <p className={styles.text}>12.1 Cesión del contrato. El cliente no podrá ceder o transmitir el contrato, los derechos contractuales o las obligaciones a un tercero sin haber obtenido la autorización expresa y previa de Calendab. Calendab tiene derecho a ceder la relación contractual con el cliente a una empresa afiliada a Calendab.</p>
                            <p className={styles.text}>12.2 Modificación del contrato y de las CGC. Calendab podrá modificar en cualquier momento el contenido del contrato en vigor y de las presentes Condiciones Generales de Contratación con el consentimiento del cliente. En caso de que el cliente no se oponga a los cambios en un plazo máximo de cuatro semanas desde que se reciba la notificación de los mismos, el consentimiento se considerará otorgado. Calendab comunicará al cliente las modificaciones que se disponga a realizar acerca del contrato vigente (en especial, las adaptaciones de los precios) y las presentes CGC con una antelación razonable, al menos de cuatro semanas. Calendab se compromete a informar al cliente sobre las consecuencias de no manifestar su oposición al comunicarle los cambios. Siempre y cuando una modificación planeada del contrato vigente o de estas CGC modifique los derechos elementales del cliente o las obligaciones elementales de Calendab respecto del cliente de una forma que perjudique al cliente más que insignificantemente, Calendab dará la oportunidad de resolver el contrato antes de la eficacia de la modificación mediante la concesión de un derecho especial de rescisión. La remuneración abonada por anticipado por parte del cliente respecto de períodos posteriores a la fecha de rescisión será devuelta de forma parcial por Calendab en caso de rescisión anticipada en base a dicha razón.</p>
                            <p className={styles.text}>12.3 Avisos y anuncios. A menos que se establezca lo contrario, los avisos y anuncios relativos a la ejecución del presente contrato se efectuarán de forma escrita (por ejemplo, por correo electrónico). Calendab podrá realizarlas a través de la dirección de correo electrónico especificada por el cliente en la orden de pedido. Cambios en este sentido deberán ser informados a Calendab sin demora por parte del cliente.</p>
                            <p className={styles.text}>12.4 Forma escrita. Cualquier modificación de este contrato deberá hacerse de forma escrita (por ejemplo, por correo electrónico, correo postal o fax). Esto también se aplicará a cualquier dispensa de este requerimiento.</p>
                            <p className={styles.text}>12.5 Compensación, retención. El cliente solo podrá compensar deudas con Calendab cuando estas no se discutan o se consideren firmes. El derecho de retención o la excepción por incumplimiento del contrato solo estará disponible para el cliente acerca de derechos no discutidos o considerados firmes dentro del marco de esta relación contractual.</p>
                            <p className={styles.text}>12.6 Derecho aplicable. Este contrato se rige exclusivamente por el derecho español, excluyendo la Convención de las Naciones Unidas sobre los Contratos de Compraventa Internacional de Mercaderías. No se aplicará el derecho de colisión.</p>
                            <p className={styles.text}>12.7 Jurisdicción competente. Bien sea el cliente una persona física, jurídica, de derecho público o de patrimonios especiales de carácter público, la única jurisdicción competente es la correspondiente al domicilio social de Calendab. Calendab tiene la facultad de demandar al cliente en su sede.</p>
                            <p className={styles.text}>12.8 Aplicabilidad parcial. Si alguna disposición de estas Condiciones Generales de Contratación sea o resulte inválida, la validez del resto de las disposiciones no se verá afectada. En lugar de la disposición inválida se aplicará aquello que las partes hubieran razonablemente acordado a la luz de su intención (económica) original. Lo mismo aplicará ante una laguna contractual.</p>
                        </div>
                    </Container>
                </div>
            </div>
        </DefaultLayout>
    )
}
    
export default Legal
    